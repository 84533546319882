import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import Search from '../ui/components/Search';
import { searchIndices } from '../ui/layouts/Header/HeaderNav';
import PowerBrand from '../images/power-brand.png';
import Layout from '../layout/primary';

export const ImageBrand = styled.img`
  display: flex;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const SearchWrapper = styled.div`
  max-width: 100rem;
  margin: 0 auto;
`;

const SearchPage = ({ location }) => {
  // eslint-disable-next-line no-unused-vars
  const [searchPage, setSearchPage] = useState(true);
  const queryParams = queryString.parse(location.search);
  useEffect(() => {}, [queryParams]);
  return (
    <Layout>
      <ImageBrand src={PowerBrand} alt="Power Engineers Logo" />
      <SearchWrapper>
        <Search indices={searchIndices} searchPage={searchPage} queryParams={queryParams} />
      </SearchWrapper>
    </Layout>
  );
};

export default SearchPage;
